$(document).ready(function() {
    
    $(window).scroll(function() {
        
        var scroll = $(window).scrollTop();
        
        if(scroll > 0) {
            $('body > header').addClass('sticky');
        } else if(scroll === 0) {
            $('body > header').removeClass('sticky');
        }
        
        if(scroll > 300) {
            var y = (scroll-300) / 100,
                scale = (100 + (scroll-300) / 5) / 200;
            
            if(scale >= 1.25) {
                scale = 1.25;
            }
            
            $('.home__hero img').css({
                transform: 'translate3d(-50%, -'+y+'%, 0) scale('+scale+')'
            });
        }
        
    });
    
    $('.home__panel-grid--block').on('click', 'a[data-fancybox]', function() {
        var title = $(this).parents('p').siblings('figure').find('h3').html();
        $('#enquire h2').html(title);
    });
    
    $('.header__menu, .header__close').on('click', function() {
        $('.header__menu').toggleClass('open');        
    });
   
    $('.shops__location--cgi').on('click', 'nav a[data-block]', function(e) {
        $('.shops__location--cgi img.svg').removeClass('active');
        $('.shops__location--cgi img.svg.'+$(this).data('block')).addClass('active');
        $('.shops__location--cgi nav a').removeClass('active');
        $(this).addClass('active');
    });
    
    // Location page map
    if($('.location__intro #gmap').length > 0) {
        
        var map_style = [
                {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
                },
                {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
                },
                {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
                },
                {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
                },
                {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#bdbdbd"
                  }
                ]
                },
                {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
                },
                {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
                },
                {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
                },
                {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                },
                {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
                },
                {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#DDDDDD"
                  }
                ]
                },
                {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
                },                
                {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#dadada"
                  }
                ]
                },
                {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
                },
                {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                },
                {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
                },
                 {
                "featureType": "transit.station",
                "stylers": [
                  {
                    "visibility": "on"
                  }
                ]
                },
                {
                "featureType": "transit.station.bus",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
                },
                {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#c9c9c9"
                  }
                ]
                },
                {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                }
            ];
        
        var lat = 51.751206,
            lng = -1.297516,
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds();

        var location_map = new google.maps.Map(document.getElementById('gmap'), {
            center: point,
            zoom: 15,
            scrollwheel: false,
            styles: map_style
        });
        
        var marker = new google.maps.Marker({
            position: point,
            map: location_map,
            icon: '/images/marker.png',
        });
        
    }
    
    new WOW().init();
    
});